import ButtonCentered from "./ButtonCentered";
import React, { useEffect } from "react";

function Mixing() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="main-grid mixing-grid">
        <div className="mixing-h">
          <h1 className="h1">
            Mixing, Mastering & Audio Editing/Repair Services
          </h1>
          <p className="p">
            We offer professional mixing, mastering and audio repair/editing
            services for a wide range of genres and platforms. Regardless of
            your specific needs, we will get your audio content ready for
            broadcast or release.
          </p>
        </div>
        <div className="mixing-p">
          <h2 className="h2">Mixing/Mastering</h2>
          <p className="p2">
            With years of mixing and audio experience, we’ll use our knowledge
            to transform your unfinished, dull or problematic mix into a
            professional piece of broadcast ready audio.
          </p>
          <h2 className="h2">Audio Editing/Repair</h2>
          <p className="p2 bottom-margin">
            Give us the labourious task of editing and mixing the audio for your
            3 hour long podcast or video essay, or fixing the horrible
            distortion in a crucial audio track that you accidentally recorded a
            little too hot. We got you.
          </p>
        </div>
      </div>
      <div className="mixing-button-contact">
        <ButtonCentered link="/contact" text="Contact" />
      </div>
    </>
  );
}

export default Mixing;
