import "./App.css";
import Nav from "./Components/Nav";
import Home from "./Components/Home";
import Footer from "./Components/Footer";
import Mixing from "./Components/Mixing";
import Samples from "./Components/Samples";
import Sample from "./Components/Sample";
import SoundDesign from "./Components/SoundDesign";
import Lessons from "./Components/Lessons";
import Contact from "./Components/Contact";
import About from "./Components/About";
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <div className="App-container">
        <Nav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/mixing" element={<Mixing />} />
          <Route path="/samplepacks" element={<Samples />} />
          <Route path="/samplepacks/:id" element={<Sample />} />
          <Route path="/sounddesign" element={<SoundDesign />} />
          <Route path="/lessons" element={<Lessons />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
        </Routes>
        <Footer />
      </div>
    </div>
  );
}

export default App;
