import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";
import { Link } from "react-router-dom";
import { RiTwitterFill } from "react-icons/ri";
import { RiInstagramFill } from "react-icons/ri";
import { RiSoundcloudFill } from "react-icons/ri";
import { RiYoutubeFill } from "react-icons/ri";
import { IconContext } from "react-icons";

function Nav() {
  return (
    <header className="App-header">
      <nav className="nav">
        <Link to="/" style={{ color: "inherit", textDecoration: "inherit" }}>
          <div className="logo">Emergent Sound</div>
        </Link>
        <div className="desktop-only">
          <DesktopNav />
        </div>
        <div className="mobile-only">
          <MobileNav />
        </div>
        <ul className="social-links-desktop">
          <IconContext.Provider
            value={{
              color: "white",
              className: "react-icons",
              size: "1.4rem",
            }}
          >
            <a
              href="https://www.instagram.com/emergent_sound/"
              target="_blank"
              rel="noreferrer"
            >
              <RiInstagramFill />
            </a>
            <a
              href="https://twitter.com/EmergentSound"
              target="_blank"
              rel="noreferrer"
            >
              <RiTwitterFill />
            </a>
            <a
              href="https://soundcloud.com/emergent-sound"
              target="_blank"
              rel="noreferrer"
            >
              <RiSoundcloudFill />
            </a>
            <a
              href="https://www.youtube.com/c/Naircol"
              target="_blank"
              rel="noreferrer"
            >
              <RiYoutubeFill />
            </a>
          </IconContext.Provider>
        </ul>
      </nav>
    </header>
  );
}

export default Nav;
