import React, { useEffect } from "react";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about-page">
      <div className="img-container img-about">
        <img
          className="img"
          src="./images/Matt-Hennessy.jpg"
          alt="ableton live software"
        />
      </div>
      <div>
        <h1 className="h1 h1-small">Matt Hennessy</h1>
        <p className="p2 bottom-margin">
          Emergent Sound was created in 2022 by Matt Hennessy as a means to turn
          his passion for audio into a career.
          <br />
          <br />
          Working as a freelance mixing/mastering engineer and sound designer,
          he also values sharing his knowledge, whether it be through private
          lessons or Youtube videos under his alias 'Naircol'. Matt also
          produces music of his own under the same moniker.
          <br />
          <br />
          With a lifelong obsession for music and audio, and a focused drive for
          learning, Matt has accrued a weath of knowledge and experience that
          will make any sonic goals you might have in your imagination a
          reality.
        </p>
      </div>
    </div>
  );
}

export default About;
