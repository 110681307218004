import { HiOutlineMenuAlt4 } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
import React, { useState } from "react";
import NavLinks from "./NavLinks";

function MobileNav() {
  const [open, setOpen] = useState(false);

  const hamburgerIcon = (
    <HiOutlineMenuAlt4
      className="nav-icon"
      size={40}
      onClick={() => {
        setOpen(!open);
      }}
    />
  );

  const closeIcon = (
    <IoMdClose
      className="nav-icon"
      size={40}
      onClick={() => {
        setOpen(!open);
      }}
    />
  );
  return (
    <div className={open ? "mobile-nav-margin" : ""}>
      {open ? closeIcon : hamburgerIcon}
      {open && <NavLinks open={open} setOpen={setOpen} />}
    </div>
  );
}

export default MobileNav;
