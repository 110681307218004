import { Link } from "react-router-dom";

function Centered({ link, text }) {
  return (
    <Link to={link} style={{ color: "inherit", textDecoration: "inherit" }}>
      <div className="page-button-centered">{text}</div>
    </Link>
  );
}

export default Centered;
