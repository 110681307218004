import { useNavigate } from "react-router-dom";
import { useState } from "react";

function ContactForm() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isPending, setIsPending] = useState(false);

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const contact = { firstName, lastName, email, message };

    setIsPending(true);

    await fetch("https://formspree.io/f/xknyqlqg", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(contact),
    });

    alert("Thank you, your message has been sent");
    setIsPending(false);
    navigate("/");
  };

  return (
    <div classname="form-container">
      <form onSubmit={handleSubmit} className="form-container">
        <p className="form-label">Name *</p>
        <div className="form-names">
          <span className="name-column">
            <input
              type="text"
              id="firstName"
              maxLength="50"
              required
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className="form-input"
            />
            <label htmlFor="firstName" className="form-label-small">
              First Name
            </label>
          </span>
          <span className="name-column">
            <input
              type="text"
              id="lastName"
              maxLength="50"
              required
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className="form-input"
            />
            <label htmlFor="lastName" className="form-label-small">
              Last Name
            </label>
          </span>
        </div>
        <div className="email-form">
          <label htmlFor="email" className="form-label">
            Email *
          </label>
          <input
            maxLength="50"
            type="text"
            id="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-input"
          />
        </div>
        <div className="message-form">
          <label htmlFor="message" className="form-label">
            Message *
          </label>
          <textarea
            id="message"
            maxLength="250"
            required
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="message-input"
          />
        </div>
        {!isPending && (
          <button className="page-button contact-send">Send</button>
        )}
        {isPending && (
          <button className="page-button contact-send">Sending...</button>
        )}
      </form>
    </div>
  );
}

export default ContactForm;
