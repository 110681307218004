import NavLinks from "./NavLinks";

function DesktopNav() {
  return (
    <>
      <NavLinks />
    </>
  );
}

export default DesktopNav;
