import { Link } from "react-router-dom";

function Button({ link, text }) {
  return (
    <Link to={link} style={{ color: "inherit", textDecoration: "inherit" }}>
      <div className="page-button">{text}</div>
    </Link>
  );
}

export default Button;
