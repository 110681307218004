import Button from "./Button";
import HomeData from "../Data/HomeData.json";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function Home() {
  let align = "R";
  const [mobile, setMobile] = useState("");
  let windowWidth = window.innerWidth;

  const arrangeContent = () => {
    windowWidth = window.innerWidth;
    if (windowWidth <= 979.2) {
      return setMobile("true"), console.log(mobile);
    }
    if (windowWidth > 979.2) {
      return setMobile("false"), console.log(mobile);
    }
  };
  useEffect(() => {
    if (windowWidth <= 979.2) {
      return setMobile("true");
    }
    if (windowWidth > 979.2) {
      return setMobile("false");
    }
  }, []);

  window.onload = function () {
    arrangeContent();
  };

  window.onresize = arrangeContent;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <ul className="ul">
        {HomeData.map((item) => {
          if (mobile === "true") {
            return (
              <li key={item.id} className="li main-grid">
                <Link
                  to={item.link}
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  <div className="img-container">
                    <img className="img" src={item.image} alt={item.heading} />
                  </div>
                </Link>
                <div>
                  <h1 className="h1">{item.heading}</h1>
                  <p className="p">{item.description}</p>
                  <div className="mixing-contact-button">
                    <Button link={item.link} text="Learn More" />
                  </div>
                </div>
              </li>
            );
          }
          if (align === "R") {
            align = "L";
            return (
              <li key={item.id} className="li main-grid">
                <div>
                  <h1 className="h1">{item.heading}</h1>
                  <p className="p">{item.description}</p>
                  <div className="mixing-contact-button">
                    <Button link={item.link} text="Learn More" />
                  </div>
                </div>
                <Link
                  to={item.link}
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  <div className="img-container">
                    <img className="img" src={item.image} alt={item.heading} />
                  </div>
                </Link>
              </li>
            );
          }
          if (align === "L") {
            align = "R";
            return (
              <li key={item.id} className="li main-grid">
                <Link
                  to={item.link}
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  <div className="img-container">
                    <img className="img" src={item.image} alt={item.heading} />
                  </div>
                </Link>
                <div>
                  <h1 className="h1">{item.heading}</h1>
                  <p className="p">{item.description}</p>
                  <div className="mixing-contact-button">
                    <Button link={item.link} text="Learn More" />
                  </div>
                </div>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
}

export default Home;
