import React, { useEffect } from "react";
import { PopupButton } from "react-calendly";

function Lessons() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="lessons-page">
      <div className="img-container img-lesson">
        <img className="img" src="./images/lessons.jpg" alt="lesson image" />
      </div>
      <div>
        <h1 className="h1 h1-small">1 on 1 Sound Design & Music Lessons</h1>
        <p className="p2 bottom-margin">
          If you’d like to make your own sounds or learn the skills required to
          be succesful making your own music, we offer quality lessons tailored
          to your personal goals and needs using Ableton Live.
        </p>
        <p className="p3">
          Sound Design/Music Production Lessons in Ableton Live:
          <br />
          <br />
          30 Minute Lesson - $60 (NZD)
          <br />
          1 Hour Lesson - $100 (NZD)
          <br />
          2 Hour Lesson - $180 (NZD)
          <br />
          <br />
          If you’re interested in lessons, please book a free 30 minute
          introductory lesson where we can decide on the best course of action
          to meet your specific needs.
          <br />
          <br />
          IMPORTANT: Please state your desired lesson length in your booking
          message (all lessons booked in 1 hour blocks on calendar regardless of
          duration).
          <br />
          For 2 hour lessons, please make 2 bookings.
        </p>
        {/* <Button link="/contact" text="Book Now" /> */}
        <PopupButton
          url="https://calendly.com/emergent-sound/1on1"
          /*
           * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
           * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
           */
          rootElement={document.getElementById("root")}
          text="Book Now"
        />
      </div>
    </div>
  );
}

export default Lessons;
