import { RiTwitterFill } from "react-icons/ri";
import { RiInstagramFill } from "react-icons/ri";
import { RiSoundcloudFill } from "react-icons/ri";
import { RiYoutubeFill } from "react-icons/ri";
import { IconContext } from "react-icons";
import ContactForm from "./ContactForm";
import React, { useEffect } from "react";

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="contact-grid">
      <div className="contact-info">
        <h1 className="h1 euro-text">Contact us.</h1>
        <address className="p3">
          <a href="mailto:matt.h@emergent-sound.com" className="email">
            matt.h@emergent-sound.com
          </a>
        </address>

        <IconContext.Provider
          value={{ color: "white", className: "react-icons", size: "1.4rem" }}
        >
          <a href="#">
            <RiInstagramFill />
          </a>
          <a href="#">
            <RiTwitterFill />
          </a>
          <a href="#">
            <RiSoundcloudFill />
          </a>
          <a href="#">
            <RiYoutubeFill />
          </a>
        </IconContext.Provider>
      </div>
      <div className="contact-form">
        <ContactForm />
      </div>
    </div>
  );
}

export default Contact;
