import { useNavigate } from "react-router-dom";
import SamplePackData from "../Data/SamplePackData.json";
import React, { useEffect } from "react";

function Samples() {
  let navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="samples-page">
      <h1 className="h1 euro-text">SAMPLE PACKS</h1>
      <hr className="hr" />
      <ul className="ul samples-grid">
        {SamplePackData.map((item) => {
          return (
            <li
              key={item.id}
              className="li sample-link"
              onClick={() => navigate(`/samplepacks/` + item.id)}
            >
              <div className="img-container">
                <img className="img" src={item.image} alt={item.heading} />
              </div>
              <h2 className="h2 euro-text">{item.heading}</h2>
              <p className="p">${item.price}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default Samples;
