import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

function Footer() {
  let navigate = useNavigate();
  return (
    <footer className="footer">
      <ul className="ul footer">
        <Link
          to="/about"
          style={{ color: "inherit", textDecoration: "inherit" }}
        >
          <li className="footer-item" onClick={() => navigate("#")}>
            About
          </li>
        </Link>
        <Link
          to="/contact"
          style={{ color: "inherit", textDecoration: "inherit" }}
        >
          <li className="footer-item" onClick={() => navigate("#")}>
            Contact
          </li>
        </Link>
      </ul>
    </footer>
  );
}

export default Footer;
