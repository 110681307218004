import SamplePackData from "../Data/SamplePackData.json";
import { useParams } from "react-router-dom";
import React, { useEffect } from "react";

function Samples() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let { id } = useParams();
  const samplePack = SamplePackData.filter((sample) => sample.id === id);
  return (
    <div className="sample-grid">
      <div className="img-container">
        <img
          className="img"
          src={`.` + samplePack[0].image}
          alt={samplePack[0].heading}
        />
      </div>
      <div>
        <h1 className="h1 euro-text">{samplePack[0].heading}</h1>
        <p className="p p-large">${samplePack[0].price}</p>
        <p className="p2">{samplePack[0].description}</p>
        <a href={samplePack[0].link} title={samplePack[0].heading}>
          <button>Buy Now</button>
        </a>
      </div>
    </div>
  );
}

export default Samples;
