import { Link } from "react-router-dom";
import { RiTwitterFill } from "react-icons/ri";
import { RiInstagramFill } from "react-icons/ri";
import { RiSoundcloudFill } from "react-icons/ri";
import { RiYoutubeFill } from "react-icons/ri";
import { IconContext } from "react-icons";

function NavLinks({ open, setOpen }) {
  const closeMenu = () => {
    setOpen(false);
  };

  return (
    <>
      <ul className="nav-links">
        <div className="nav-links-container">
          <Link
            to="/mixing"
            style={{ color: "inherit", textDecoration: "inherit" }}
            className="nav-item"
          >
            <li className="nav-item-text" onClick={closeMenu}>
              MIXING/MASTERING
            </li>
          </Link>
          <Link
            to="/samplepacks"
            style={{ color: "inherit", textDecoration: "inherit" }}
            className="nav-item"
          >
            <li className="nav-item-text" onClick={closeMenu}>
              SAMPLE PACKS
            </li>
          </Link>
          <Link
            to="/sounddesign"
            style={{ color: "inherit", textDecoration: "inherit" }}
            className="nav-item"
          >
            <li className="nav-item-text" onClick={closeMenu}>
              SOUND DESIGN
            </li>
          </Link>
          <Link
            to="lessons"
            style={{ color: "inherit", textDecoration: "inherit" }}
            className="nav-item"
          >
            <li className="nav-item-text" onClick={closeMenu}>
              LESSONS
            </li>
          </Link>
          <li className="social-links-container">
            <ul className="social-links-mobile">
              <IconContext.Provider
                value={{
                  color: "white",
                  className: "react-icons",
                  size: "1.4rem",
                }}
              >
                <a
                  href="https://www.instagram.com/emergent_sound/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <RiInstagramFill />
                </a>
                <a
                  href="https://twitter.com/EmergentSound"
                  target="_blank"
                  rel="noreferrer"
                >
                  <RiTwitterFill />
                </a>
                <a
                  href="https://soundcloud.com/emergent-sound"
                  target="_blank"
                  rel="noreferrer"
                >
                  <RiSoundcloudFill />
                </a>
                <a
                  href="https://www.youtube.com/c/Naircol"
                  target="_blank"
                  rel="noreferrer"
                >
                  <RiYoutubeFill />
                </a>
              </IconContext.Provider>
            </ul>
          </li>
        </div>
      </ul>
    </>
  );
}

export default NavLinks;
