import Button from "./Button";
import React, { useEffect } from "react";

function SoundDesign() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="sounddesign-page">
      <h1 className="h1 euro">Sound Design Showreel</h1>
      <p className="p">
        If you're interested in our sound design services, please check out our
        showreel and get in contact!
      </p>
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/dQUIzRADwFU"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <Button link="/contact" text="Contact" />
    </div>
  );
}

export default SoundDesign;
